/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Button, Title, Subtitle } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--style3 --full" anim={""} name={"uvod"} animS={"3"} style={{"backgroundColor":"var(--color-custom-2)"}} border={""} fullscreen={true} css={css`
      background-position: 55% 6%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":850}}>
              
              <Image style={{"maxWidth":302}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/40288/8f3366be67ae4d9789adb21384de6c17_s=350x_.jpeg 350w"} alt={""} src={"https://cdn.swbpg.com/t/40288/8f3366be67ae4d9789adb21384de6c17_s=350x_.jpeg"}>
              </Image>

              <Text className="text-box text-box--justify fs--20 w--900 text-box--invert" style={{"maxWidth":875,"paddingLeft":0}} content={"<br>Naše specializace je špičkový servis jak baterií, tak elektrokola jako celek. Umíme nabídnout opravu / výměnu (repas baterie) za bezkonkurenční cenu. Servis elektrokol probíhá v nově vybavené, moderní dílně ve spolupráci s profesionály v oboru. Využijte našich služeb.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--pbtn4 btn-box--shape4 btn-box--cColor2 btn-box--shadow5 btn-box--justify fs--20 mt--30" style={{"paddingTop":0,"paddingLeft":0,"maxWidth":390,"paddingRight":0}} content={"Nabídka&nbsp;<br>"} use={"page"} href={"/#sluzby"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--pbtn4 btn-box--shape4 btn-box--cColor2 btn-box--shadow5 btn-box--justify fs--20 mt--30" style={{"paddingRight":0,"maxWidth":462,"paddingLeft":1,"paddingTop":0}} content={"Zajímavost z oboru&nbsp;<br>"} use={"page"} href={"/#informace"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--pbtn4 btn-box--shape4 btn-box--cColor2 btn-box--shadow5 btn-box--justify fs--20 mt--30" content={"Nevím<br>"} use={"page"} href={"/#o-mne"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--pbtn4 btn-box--shape4 btn-box--cColor2 btn-box--shadow5 btn-box--justify fs--20 mt--30" content={"Kontakt<br>"} use={"page"} href={"/#kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-4qhsb1 --style2 --full pb--60 pt--60" name={"sluzby"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/168/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Krok jedna&nbsp;<br>"}>
              </Title>

              <Text className="text-box fs--16" content={"Každá baterie má se občas ozve, že už prostě dál nemůže. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; A co dál ? Nezbývá než navštívit náš profesionální servis, kde Vám uděláme diagnostiku baterie."}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Krok dva<br>"}>
              </Title>

              <Text className="text-box fs--16" content={"Navrhneme sestavu článků, odpovídající požadované kapacitě. Články jako Panasonic, Samsung, Sony a další jsou samozřejmostí.<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 pb--30 pl--30 pr--30 pt--30" style={{"backgroundColor":"var(--white)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/11212/2d1925b3d1944911af33dd88cd1749bf.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":42}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"Krok tři<br>"}>
              </Title>

              <Text className="text-box fs--16" content={"Výstupní test hotové baterie, interní protokol a doma máte zase baterii jako novou za podstatně nižší cenu.<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" anim={null} name={"informace"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={""} animS={"3"} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box lh--14" content={"Něco zajímavé z oboru&nbsp;<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--50" name={"o-mne"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":453}}>
              
              <Title className="title-box fs--48" content={"Z čeho se skládá kompletní baterie<br>"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":650}} content={"Celá baterie je vlastně kompletní sestava jednotlivých článků o přibližné velikosti tužkové baterie. Sestava je vytvořena sério-paralelní kombinací článků k dosažení požadované kapacity. K celé sestavě je samozřejmě potřeba i něco, co dohlíží na správný průběh. Je to BMS. Ta kontroluje jednotlivé články, jejich správné nabíjení a vybíjení.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--shape4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40288/22fa5b2315ed44d4a69bd204a5c8e7a6_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/40288/22fa5b2315ed44d4a69bd204a5c8e7a6_s=350x_.jpg 350w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"reference"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--white);\">Postup&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--25" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box mt--25 pb--0 pl--0 pr--0 pt--0" content={"<span style=\"color: var(--white);\">nápis</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box mt--25 pb--0 pl--0 pr--0 pt--0" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">napis</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box mt--25 pb--0 pl--0 pr--0 pt--0" content={"<span style=\"color: var(--white);\">nápis</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--30" name={"kontakt"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --shape5 --center el--1 pb--50 pt--50 flex--center" style={{"backgroundColor":"var(--white)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":595}}>
              
              <Title className="title-box fs--43" content={"Ozvěte se mi&nbsp;<br>Karel Doffek<br>.....<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}